.hmsui-chatBox-headerRoot {
  justify-content: flex-end !important;
}

.hmsui-chatBox-headerText {
  display: none !important;
}

.hmsui-participantList-menuRoot {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.hmsui-participantList-menuSection {
  display: none !important;
}
