.hmsui-mecountdown-container-static {
  transition: all 0.3s ease;
}

.hmsui-mecountdown-container-floating {
  position: fixed;
  transform: translate(-50%, -50%) scale(2);
  background-color: #000;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 3px 1px #333;
  transition: all 0.3s ease;
}
