.hmsui-participantList-scrollbar::-webkit-scrollbar {
  background: none;
  width: 4px;
}

.hmsui-participantList-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #5e5e5e;
}

.hmsui-participantList-menuItem .hmsui-participantList-show-on-group-hover {
  visibility: hidden;
}

.hmsui-participantList-menuItem:hover
  .hmsui-participantList-show-on-group-hover {
  visibility: visible;
}
