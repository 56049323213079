.bottom-background {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.hmsui-preview-root {
  min-height: 30rem;
}

.hmsui-avatar-root {
  padding-top: 0;
}